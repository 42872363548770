import axios from "axios";
import { keys } from "../utils/keys";

export const getCMSPageMeta = async (identifier) => {
  const axiosRes = await axios.get(keys.general.graphqlUrl, {
    paramsSerializer: { indexes: null },
    params: {
      query: `{
        cmsPage (identifier: "${identifier}") {
          title meta_title meta_keywords meta_description
        }
      }`,
    },
  });
  return axiosRes.data;
};

export const getBlogPageMeta = async (url_key) => {
  const axiosRes = await axios.post(keys.general.graphqlUrl, {
    query: `query GetBlogPost($url_key: String!) {
      blogPostByUrlKey (url_key: $url_key) {
        title meta_keywords meta_description
      }
    }`,
    variables: { url_key },
  });
  return axiosRes.data;
};

export const getCmsPage = async (pageId) => {
  const axiosRes = await axios.get(keys.general.graphqlUrl, {
    paramsSerializer: { indexes: null },
    params: {
      query: `{
        cmsPage(id: "${pageId}") {
          title url_key content identifier page_layout meta_title meta_keywords content_heading meta_description
        }
      }`,
    },
  });
  return axiosRes.data;
};

// Fetch Blog Post List with pagination
export const getBlogPostList = async (page_size = 10, page = 1) => {
  const axiosRes = await axios.post(keys.general.graphqlUrl, {
    query: `
      query GetBlogPostList($page: Int, $page_size: Int) {
        blogPostList(page: $page, page_size: $page_size) {
          page_size total_count current_page
          items {
            id title url_key short_content status created_at updated_at publish_date
          }
        }
      }
    `,
    variables: { page, page_size },
  });
  return axiosRes.data;
};

export const getBlogPostByUrlKey = async (url_key) => {
  const axiosRes = await axios.post(keys.general.graphqlUrl, {
    variables: { url_key },
    query: `
        query GetBlogPost($url_key: String!) {
          blogPostByUrlKey(url_key: $url_key) {
            id title status url_key content store_id author_id created_at updated_at category_id is_featured publish_date short_content meta_keywords customer_groups meta_description is_allow_comments author_display_mode is_author_badge_enabled are_related_products_enabled
          }
        }
      `,
  });
  return axiosRes.data;
};
